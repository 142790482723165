import React, { useState, useEffect } from 'react'

import axios from 'axios'
import { getStringParamFromURL } from 'utils/urlParser'
import config from 'config'

import { getReportTemplate, getFollowUpTypes } from './helpers'

import Wizard from 'components/Wizard'

import Overview from 'pages/Overview'
import DrugDosage from 'pages/DrugDosage'
import DrugIndication from 'pages/DrugIndication'
import DrugBatchNumber from 'pages/DrugBatchNumber'
import EffectStatus from 'pages/EffectStatus'
import DrugAction from 'pages/DrugAction'
import Confirmation from 'pages/Confirmation'
import Thanks from 'pages/Thanks'

import {
  QUESTION_DRUG_DOSAGE,
  QUESTION_DRUG_INDICATION,
  QUESTION_DRUG_BATCH_NUMBER,
  QUESTION_EFFECT_STATUS,
  QUESTION_CUSTOM,
  QUESTION_TEMPLATE,
  QUESTION_DRUG_ACTION
} from '../../constants'

const ReportWizard = props => {
  const reportValidationSchema = ''

  const [reportTemplate, setReportTemplate] = useState('')

  const [reportData, setReportData] = useState([])
  const [questionData, setQuestionData] = useState([])
  const [customQuestionData, setCustomQuestionData] = useState([])
  const [questionTypes, setQuestionTypes] = useState([])

  useEffect(async () => {
    const token = getStringParamFromURL(window.location.search, 'token')
    const headers = {
      'X-API-Version': '3.0.0',
      'X-Event-App-Created': 'med-app-followup',
      'X-Event-Role': 'patient',
      Authorization: `Bearer ${token}`
    }
    // regex to capture reportId from URL path: /follow-ups/report/(:reportId)
    const captureReportIDRegex = /^\/follow-ups\/report\/([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9Aa-f]{3}-[0-9a-f]{12})/
    if (captureReportIDRegex.test(window.location.pathname)) {
      const reportId = captureReportIDRegex.exec(window.location.pathname)[1]
      axios
        .get(`${config.EVENTSTORE_BASE_URL}/patient/report/${reportId}`, {
          headers: headers
        })
        .then(res => {
          const report = res.data
          const questions = report.metaData.questions.fromPharma.toPatient.filter(
            question => !question.answer
          )
          const followUpQuestions = getFollowUpTypes(questions)
          const customQuestions = questions.filter(
            item =>
              item.type === QUESTION_CUSTOM || item.type === QUESTION_TEMPLATE
          )

          setReportData(report)
          setQuestionData(questions)
          setCustomQuestionData(customQuestions)
          setQuestionTypes(followUpQuestions)
          setReportTemplate(getReportTemplate(report, customQuestions))
        })
    } else {
      // :TODO: method just for testing, will be removed in further ticket
      const followUpQuestions = [
        QUESTION_DRUG_DOSAGE,
        QUESTION_EFFECT_STATUS,
        QUESTION_TEMPLATE,
        QUESTION_DRUG_ACTION
      ]
      const report = {
        drugs: ['testi', 'miep'],
        effects: ['ho', 'hey']
      }
      setReportData(report)
      setQuestionTypes(followUpQuestions)
      console.log('Etwas ist falsch gelaufen')
    }
  }, [])

  const handleSubmit = () => {
    console.log('taaaadaaaaa *party*')
  }

  return (
    <Wizard
      initialValues={reportTemplate}
      onSubmit={handleSubmit}
      validationSchema={reportValidationSchema}
    >
      <Wizard.Page category='overview'>
        <Overview questionTypes={questionTypes} />
      </Wizard.Page>
      {questionTypes.includes(QUESTION_DRUG_DOSAGE) &&
        reportData.drugs.map((item, index) => [
          <Wizard.Page key={index} category='dosage'>
            <DrugDosage drug={item} />
          </Wizard.Page>
        ])}
      {questionTypes.includes(QUESTION_DRUG_INDICATION) &&
        reportData.drugs.map((item, index) => [
          <Wizard.Page key={index} category='indication'>
            <DrugIndication drug={item} />
          </Wizard.Page>
        ])}
      {questionTypes.includes(QUESTION_DRUG_BATCH_NUMBER) &&
        reportData.drugs.map((item, index) => [
          <Wizard.Page key={index} category='batchNumber'>
            <DrugBatchNumber drug={item} />
          </Wizard.Page>
        ])}
      {questionTypes.includes(QUESTION_EFFECT_STATUS) &&
        reportData.effects.map((item, index) => [
          <Wizard.Page key={index} category='effectStatus'>
            <EffectStatus effect={item} />
          </Wizard.Page>
        ])}
      {questionTypes.includes(QUESTION_DRUG_ACTION) &&
        reportData.drugs.map((item, index) => [
          <Wizard.Page key={index} category='action'>
            <DrugAction drug={item} />
          </Wizard.Page>
        ])}
      <Wizard.Page category='confirmation'>
        <Confirmation />
      </Wizard.Page>
      <Wizard.Page category='complete'>
        <Thanks />
      </Wizard.Page>
    </Wizard>
  )
}

export default ReportWizard

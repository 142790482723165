import React from 'react'
import clsx from 'clsx'
import I18n from 'i18n'

import Link from '@material-ui/core/Link'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    textAlign: 'center'
  },
  element: {
    marginRight: theme.spacing(1)
  }
}))

const Footer = ({ className }) => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.root, className)}>
      <div>
        <Link
          className={classes.element}
          href='https://www.nebenwirkungen.de/datenschutz'
          target='_blank'
        >
          {I18n.t('components.footer.dataPrivacy')}
        </Link>
        <Link
          className={classes.element}
          href='https://www.nebenwirkungen.de/impressum'
          target='_blank'
        >
          {I18n.t('components.footer.imprint')}
        </Link>
      </div>
      <div>
        © {new Date().getFullYear()} nebenwirkungen.de | MEDIKURA Digital Health
        GmbH
      </div>
    </div>
  )
}
export default Footer

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './containers/App'
import reportWebVitals from './reportWebVitals'
import config from 'config'

ReactDOM.render(<App />, document.getElementById('root'))

if (config.NODE_ENV === 'development') {
  reportWebVitals(console.log)
}

import React from 'react'
import { withTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import MedButton from '@bit/medikura.med-lib-uno.med-button'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '1rem',
    '& button': {
      margin: 0
    }
  }
}))

const NextButton = ({ type, label, disabled, onClick, t, progress }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <MedButton
        type={type || 'submit'}
        fullWidth
        size='large'
        disabled={disabled}
        onClick={onClick}
        color={disabled ? 'default' : 'primary'}
        progress={progress || false}
      >
        {label || t('common.next')}
      </MedButton>
    </div>
  )
}

export default withTranslation()(NextButton)

import React from 'react'
import I18n from 'i18n'
import { makeStyles } from '@material-ui/core/styles'

import MedTypography from '@bit/medikura.med-lib-uno.med-typography'

import NextButton from 'components/NextButton'

const useStyles = makeStyles(theme => ({
  buttonWrapper: {
    width: '12%',
    display: 'inline-block'
  },
  contentWrapper: {
    marginTop: theme.spacing(4)
  }
}))

const Confirmation = ({ goNextPage }) => {
  const classes = useStyles()

  const setAndProceed = event => {
    event.preventDefault()
    goNextPage()
  }

  return (
    <>
      <div className={classes.contentWrapper}>
        <MedTypography variant='h2'>{I18n.t('default.hello')}</MedTypography>
        <MedTypography variant='body1'>
          {I18n.t('pages.Confirmation.title')}
        </MedTypography>
      </div>
      <NextButton
        label={I18n.t('pages.Confirmation.next')}
        onClick={setAndProceed}
      />
    </>
  )
}

export default Confirmation

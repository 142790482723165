export const REPORT_SUCCESSFUL = 'REPORT_SUCCESSFUL'

export const REPORT_ERROR_DENIED = 'REPORT_ERROR_DENIED'
export const REPORT_ERROR_NOCONNECTION = 'REPORT_ERROR_NOCONNECTION'
export const REPORT_ERRORS = [REPORT_ERROR_DENIED, REPORT_ERROR_NOCONNECTION]

export const QUESTION_DRUG_DOSAGE = 'question-drug-dosage'
export const QUESTION_DRUG_INDICATION = 'question-drug-indication'
export const QUESTION_DRUG_BATCH_NUMBER = 'question-drug-batch-number'
export const QUESTION_EFFECT_STATUS = 'question-effect-status'
export const QUESTION_CUSTOM = 'custom'
export const QUESTION_TEMPLATE = 'template'
export const QUESTION_DRUG_ACTION = 'question-drug-action'

// The following values are also used as locales.
// Don't rename them without adapting the locales.
export const TIME_FRAME_OPTION_EXACT_DATE = 'exactDate'

import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import MedThemeWrapper from '@bit/medikura.med-lib-uno.med-theme-wrapper'
import { ReactComponent as LogoIcon } from 'assets/logo.svg'
import desktopImageRetinaLarge from '../../assets/desktop-picture-retina-large.jpeg'
import desktopImageRetinaMedium from '../../assets/desktop-picture-retina-medium.jpeg'
import desktopImageRetinaSmall from '../../assets/desktop-picture-retina-small.jpeg'
import desktopImageLarge from '../../assets/desktop-picture-large.jpeg'
import desktopImageMedium from '../../assets/desktop-picture-medium.jpeg'
import desktopImageSmall from '../../assets/desktop-picture-small.jpeg'

import ErrorBoundary from 'components/ErrorBoundary'
import Footer from 'components/Footer'
import ReportWizard from './../ReportWizard'

const styles = theme => ({
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    maxWidth: '1440px',
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '720px'
    },
    margin: '0 auto',
    minHeight: '100vh',
    overflow: 'hidden',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 4px 20px 0px'
  },
  headerContainer: {
    height: '75px',
    [theme.breakpoints.down('sm')]: {
      height: '50px'
    },
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 4px 4px',
    '& svg': {
      width: '66px',
      height: 'auto',
      marginTop: '11px',
      marginLeft: '16px',
      [theme.breakpoints.down('sm')]: {
        width: '40px',
        marginTop: '7px',
        marginLeft: '10px'
      }
    }
  },
  desktopWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '100%'
  },
  pictureContainer: {
    width: '50%',
    height: 'calc(100vh - 75px)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    boxShadow: 'inset rgba(0, 0, 0, 0.25) 0px 4px 4px',
    [theme.breakpoints.only('xl')]: {
      backgroundImage: 'url(' + desktopImageLarge + ')',
      ['@media ' +
      'screen and (-webkit-min-device-pixel-ratio: 2), ' +
      'screen and (min-resolution: 192dpi), ' +
      'screen and (min-resolution: 2dppx)']: {
        backgroundImage: 'url(' + desktopImageRetinaLarge + ')'
      }
    },
    [theme.breakpoints.only('lg')]: {
      backgroundImage: 'url(' + desktopImageMedium + ')',
      ['@media ' +
      'screen and (-webkit-min-device-pixel-ratio: 2), ' +
      'screen and (min-resolution: 192dpi), ' +
      'screen and (min-resolution: 2dppx)']: {
        backgroundImage: 'url(' + desktopImageRetinaMedium + ')'
      }
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(100% - 640px)',
      backgroundImage: 'url(' + desktopImageSmall + ')',
      ['@media ' +
      'screen and (-webkit-min-device-pixel-ratio: 2), ' +
      'screen and (min-resolution: 192dpi), ' +
      'screen and (min-resolution: 2dppx)']: {
        backgroundImage: 'url(' + desktopImageRetinaSmall + ')'
      }
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'calc(100vh - 75px)',
    width: '50%',
    maxWidth: '720px',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'calc(100vh - 50px)'
    },
    [theme.breakpoints.only('md')]: {
      width: '640px'
    }
  },
  flexContainer: {
    padding: '0 20px 20px'
  },
  contentContainer: {
    maxWidth: '520px',
    margin: '0 auto'
  }
})

function App ({ classes }) {
  return (
    <>
      <ErrorBoundary>
        <MedThemeWrapper theme='capture'>
          <div className={classes.pageWrapper}>
            <div className={classes.headerContainer}>
              <LogoIcon />
            </div>
            <div className={classes.desktopWrapper}>
              <div className={classes.pictureContainer} />
              <div className={classes.contentWrapper}>
                <div className={classes.flexContainer}>
                  <div className={classes.contentContainer}>
                    <ReportWizard />
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </MedThemeWrapper>
      </ErrorBoundary>
    </>
  )
}

export default withStyles(styles)(App)

// This template defines the name attribute of the form fields used for
// report creation payload. This object also holds the initial/default values
// for the field. The idea is that we define and maintain all the fields needed for
// the form here.

const getReportTemplate = (data, customQuestions) => {
  const reportTemplate = {
    drugs: [],
    effects: [],
    customQuestions: []
  }
  reportTemplate.drugs = data.drugs
  reportTemplate.effects = data.effects
  customQuestions.forEach(question => {
    reportTemplate.customQuestions.push({
      answer: '',
      _id: question._id
    })
  })
  return reportTemplate
}

// Method to filter unique question types
const uniquePrediate = (element, index, array) =>
  array.indexOf(element) === index

const getFollowUpTypes = questions => {
  const followUpQuestions = questions
    .map(question => question.type)
    .filter(uniquePrediate)
  return followUpQuestions
}

export { getReportTemplate, getFollowUpTypes }

import React from 'react'
import { Formik } from 'formik'

import ContentHeader from 'components/ContentHeader'

class Wizard extends React.Component {
  static Page = ({ children, parentState }) => {
    return <>{React.cloneElement(children, { ...parentState })}</>
  }

  constructor (props) {
    super(props)
    this.state = {
      history: [''],
      page: 0,
      childPages: props.children,
      values: props.initialValues
    }
    window.onpopstate = e => this.handleBrowserNavigation(e)

    if (window.location.hash !== '') {
      // Automatic jumping back till start when user is reloading the page
      window.history.back()
    }
  }

  getChildrenArray = () => {
    return React.Children.toArray(this.state.childPages)
  }

  handleBrowserNavigation = e => {
    const { history } = this.state

    if (
      history.length > 0 &&
      history[history.length - 1].startsWith('#complete-')
    ) {
      // Reload page if going back on results page
      window.location.reload()
    }

    if (
      history.length > 1 &&
      history[history.length - 2] === window.location.hash
    ) {
      this.handlePrevious()
    } else if (
      history.length > 0 &&
      history[history.length - 1] === window.location.hash
    ) {
      // This case happens when the user tries to go forward
      // and is returned in the else-block below
    } else {
      // Prevents users mocking around in the jumpmarks or
      // going forward again
      window.history.back()
    }
  }

  handleNext = () => {
    const children = this.getChildrenArray()
    if (this.state.page < children.length - 1) {
      const page = Math.min(this.state.page + 1, children.length - 1)

      const category = children[page].props.category
      let categoryCount = 1
      for (let i = 0; i < page; i++) {
        if (category === children[i].props.category) {
          categoryCount++
        }
      }
      window.history.pushState({}, '', '#' + category + '-' + categoryCount)

      this.setState({
        page,
        history: [...this.state.history, '#' + category + '-' + categoryCount]
      })
    }
  }

  handlePrevious = () => {
    this.setState(state => ({
      page: Math.max(state.page - 1, 0),
      history: state.history.slice(0, state.history.length - 1)
    }))
  }

  handleSubmit = values => {
    const { onSubmit } = this.props
    return onSubmit(values)
  }

  validate = async values => {}

  componentDidUpdate (prevProps) {
    if (this.props.children !== prevProps.children) {
      this.setState({
        childPages: this.props.children
      })
    }
  }

  render () {
    const { page, values } = this.state
    const activePage = this.getChildrenArray()[page]
    return (
      <>
        <Formik
          initialValues={values}
          enableReinitialize={false}
          validate={this.validate}
          onSubmit={this.handleSubmit}
        >
          {formik => (
            <>
              <form onSubmit={formik.handleSubmit}>
                <ContentHeader
                  currentPageIndex={page}
                  numberOfPages={this.getChildrenArray().length}
                  onPreviousPageClick={e => {
                    window.history.back()
                  }}
                />
                {React.cloneElement(activePage, {
                  parentState: {
                    ...formik,
                    goNextPage: this.handleNext
                  }
                })}
              </form>
            </>
          )}
        </Formik>
      </>
    )
  }
}

export default Wizard

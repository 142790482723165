import React from 'react'
import I18n from 'i18n'
import { makeStyles } from '@material-ui/core/styles'

import MedTypography from '@bit/medikura.med-lib-uno.med-typography'

import { ReactComponent as DrugDosageIcon } from 'assets/icons/dosage_grey.svg'
import { ReactComponent as DrugIndicationIcon } from 'assets/icons/indication_grey.svg'
import { ReactComponent as DrugBatchNumberIcon } from 'assets/icons/batchnumber_grey.svg'
import { ReactComponent as EffectStatusIcon } from 'assets/icons/effectstatus_grey.svg'
import { ReactComponent as OtherIcon } from 'assets/icons/other_grey.svg'
import { ReactComponent as DrugActionIcon } from 'assets/icons/action_grey.svg'

import NextButton from 'components/NextButton'

import {
  QUESTION_DRUG_DOSAGE,
  QUESTION_DRUG_INDICATION,
  QUESTION_DRUG_BATCH_NUMBER,
  QUESTION_EFFECT_STATUS,
  QUESTION_CUSTOM,
  QUESTION_TEMPLATE,
  QUESTION_DRUG_ACTION
} from '../../constants'

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      minHeight: '18rem'
    }
  },
  header: {
    fontSize: '26px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '22px'
    }
  },
  questionWrapper: {
    marginTop: '2rem',
    marginBottom: '1.5rem'
  },
  questionContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
    '& svg': {
      width: '48px',
      height: 'auto',
      marginRight: '1.5rem'
    }
  },
  questionLabel: {
    margin: '0'
  },
  infoText: {
    marginTop: '1.5rem'
  }
}))

const Overview = props => {
  const classes = useStyles()

  const setAndProceed = event => {
    event.preventDefault()
    props.goNextPage()
  }

  return (
    <>
      <div className={classes.contentWrapper}>
        <MedTypography
          variant='h2'
          color='primary'
          align='center'
          className={classes.header}
        >
          {I18n.t('pages.Overview.title')}
        </MedTypography>
        <div className={classes.questionWrapper}>
          {props.questionTypes.includes(QUESTION_DRUG_DOSAGE) && (
            <div className={classes.questionContainer}>
              <DrugDosageIcon />
              <MedTypography variant='body1' className={classes.questionLabel}>
                {I18n.t('pages.Overview.dosage')}
              </MedTypography>
            </div>
          )}
          {props.questionTypes.includes(QUESTION_DRUG_INDICATION) && (
            <div className={classes.questionContainer}>
              <DrugIndicationIcon />
              <MedTypography variant='body1' className={classes.questionLabel}>
                {I18n.t('pages.Overview.indication')}
              </MedTypography>
            </div>
          )}
          {props.questionTypes.includes(QUESTION_DRUG_BATCH_NUMBER) && (
            <div className={classes.questionContainer}>
              <DrugBatchNumberIcon />
              <MedTypography variant='body1' className={classes.questionLabel}>
                {I18n.t('pages.Overview.batchNumber')}
              </MedTypography>
            </div>
          )}
          {props.questionTypes.includes(QUESTION_EFFECT_STATUS) && (
            <div className={classes.questionContainer}>
              <EffectStatusIcon />
              <MedTypography variant='body1' className={classes.questionLabel}>
                {I18n.t('pages.Overview.effectStatus')}
              </MedTypography>
            </div>
          )}
          {(props.questionTypes.includes(QUESTION_CUSTOM) ||
            props.questionTypes.includes(QUESTION_TEMPLATE)) && (
            <div className={classes.questionContainer}>
              <OtherIcon />
              <MedTypography variant='body1' className={classes.questionLabel}>
                {I18n.t('pages.Overview.custom')}
              </MedTypography>
            </div>
          )}
          {props.questionTypes.includes(QUESTION_DRUG_ACTION) && (
            <div className={classes.questionContainer}>
              <DrugActionIcon />
              <MedTypography variant='body1' className={classes.questionLabel}>
                {I18n.t('pages.Overview.action')}
              </MedTypography>
            </div>
          )}
        </div>
        <NextButton
          label={I18n.t('pages.Overview.next')}
          onClick={setAndProceed}
        />
        <MedTypography variant='body1' className={classes.infoText}>
          {I18n.t('pages.Overview.info')}
        </MedTypography>
      </div>
    </>
  )
}

export default Overview

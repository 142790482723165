import React from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import PreviousButton from '../PreviousButton'

const useStyles = makeStyles(theme => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1.5rem'
  },
  buttonWrapper: {
    width: '2.4rem',
    display: 'inline-block',
    marginRight: '12px'
  },
  barWrapper: {
    display: 'inline-block',
    width: 'calc(100% - 2.4rem - 12px)',
    height: '1.5rem',
    position: 'relative'
  },
  fullWidth: {
    width: '100%',
    transition: 'all 1s'
  },
  barBackground: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    opacity: '0.24',
    borderRadius: '12px'
  },
  barForeground: {
    position: 'relative',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '12px',
    zIndex: '1'
  }
}))

const ContentHeader = ({
  currentPageIndex,
  numberOfPages,
  onPreviousPageClick
}) => {
  const classes = useStyles()
  const hasPreviousPage = currentPageIndex > 0
  const barForegroundWidth = ((currentPageIndex + 1) / numberOfPages) * 100

  return (
    <div className={classes.headerWrapper}>
      {hasPreviousPage && (
        <div className={classes.buttonWrapper}>
          <PreviousButton onClick={onPreviousPageClick} />
        </div>
      )}
      <div
        className={clsx(
          classes.barWrapper,
          hasPreviousPage ? '' : classes.fullWidth
        )}
      >
        <div className={classes.barBackground} />
        <div
          className={classes.barForeground}
          style={{ width: barForegroundWidth + '%' }}
        />
      </div>
    </div>
  )
}

export default ContentHeader
